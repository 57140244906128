.Footer{
    width: 100%;
    height: 30px;
    min-height: 50px;
}
@media screen and (min-width: 768px) {
    .Footer{
        height: 50px;
    }
}

.Footer p{
    display: flex;
    gap: 5px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Footer a{
    color: #fff;
}
.menu-mobil{
    background-color: rgba(19, 19, 19, 0.935);
    width: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 100;
    transform: translateX(100%);
}
.menu-mobil.show{
    transition: all 0.6s ease-in-out;
    visibility: visible;
    transform: translateX(0);
}

.hidden-menu{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.content-menu{
    position: absolute;
    width: 80%;
    height: 100%;
    background-color: #d9d9d9;
    right: 0;
    z-index: 101;
}

.itemLink{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemLink a{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #222;
    text-transform: uppercase;
    font-weight: 500;
    transition: all .3s ease-in-out;
}
.itemLink a:hover{
    background-color: #d9d9d9;
    font-size: 17px;
}

@media screen and (min-width: 1024px) {
    .itemLink a{
        height: 100px;
        width: 180px;
    }
}
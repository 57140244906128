*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.main{
    background-image: url('../Image/BackgroundResponsiv.png');
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    position: relative;
}
@media screen and (min-width: 1024px) {
    .main{
        background-image: url('../Image/Background.gif');
    }
}
.container{
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

a{
    text-decoration: none;
    display: block;
}
.Reproductor{
    max-width: 100%;
    width: 550px;
    height: 90px;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    padding: 0 10px;
    background-color: rgba(255, 255, 255, 0.211);
    backdrop-filter: blur(50px);
    margin: 0 auto 50px auto;

}

@media screen and (min-width: 1024px) {
    .Reproductor{
        height: 100px;
    }
}

.content-info-radio{
    flex: 1;
    display: flex;
    gap: 15px;
}
.content-image-music{
    width: 70px;
    height: 70px;
    border-radius: 20px;
    overflow: hidden;
}

.content-image-music .image-music{
    width: 100%;
    height: 100%;
}

.content-info-music{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}
.content-info-music h2 {
    font-size: 16px;
}

.content-info-music p {
    font-size: 13px;
}
.content-controles{
    width: 50px;
    height: 50px;
    display: flex;justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 10px;
    background-color: aliceblue;
    color: #f30039;
}
.content-main{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.content{
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.content-slogan-redes{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    flex: 1;
    flex-direction: column;
}

.content-image-redes {
    max-width: 500px;
    width: 80%;
    max-height: 100%;
}

.content-slogan-redes img{
    width: 100%;
    height: 100%;
}



.content-link-redes-sociales{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.btn-link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: #222;
}


.btnSocialMedia{
    width: 35px;
    height: 35px;
    background-color: rgb(244, 244, 244);
    border-radius: 100%;
}

.content-image-main{
    max-width: 400px;
    width: 70%;
    max-height: 50%;
    margin: 0 auto;
}

.content-image-main img{
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1024px) {
    .content-image-main{
        display: none;
    }
}
